<template>
  <section class="py-10" id="sokol">
    <v-container justify="center" align="center" fill-height class="py-10">
      <v-row justify="center">
        <v-col cols="12">
          <h3 class="display-3 text-center font-weight-thin">Sokol 640</h3>
        </v-col>
        <v-col cols="12" lg="8">
          <v-tabs v-model="tab" background-color="#2d3436" dark >
            <v-tab v-for="item in items" color="white" class="mx-3" :key="item.tab" >
              {{ item.tab }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in items" :key="item.tab" >
              <v-card color="rgba(99, 110, 114,1.0)" flat>
                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <v-col class="d-flex flex-column justify-center" :lg="(item.list || item.table) ? '4' : '12'">
                        <v-img v-if="item.image == 'side.png'" :src="getImage('front.png')" contain />
                        <v-img v-if="item.image" :src="getImage(item.image)" contain />
                      </v-col>

                      <v-divider vertical />

                      <v-col sm="12" lg="8">
                        <v-container v-if="item.list">
                          <div v-for="(value, key) in item.list" :key="key" :class="$vuetify.breakpoint.mobile ? 'item' : 'd-flex item'">
                            <div class="title black--text">{{ key }}</div>
                            <div class="content black--text">{{ value }}</div>
                          </div>
                        </v-container>

                        <v-container v-if="item.table">
                          <div class="tg-wrap">
                            <table class="tg" style="undefined;table-layout: fixed; width: 868px"> <colgroup> <col style="width: 105px"> <col style="width: 162px"> <col style="width: 601px"> </colgroup> <thead> <tr> <th class="tg-1fyk" rowspan="2">Day Camera</th> <th class="tg-1fyk">Type</th> <th class="tg-1fyk">HD</th> </tr> <tr> <th class="tg-1fyk">FOV</th> <th class="tg-1fyk">54 to 4</th> </tr> </thead> <tbody> <tr> <td class="tg-1fyk" rowspan="2">Night Camera</td> <td class="tg-1fyk">Type</td> <td class="tg-1fyk">LWIR</td> </tr> <tr> <td class="tg-1fyk">FOV</td> <td class="tg-1fyk">Optical blabla</td> </tr> <tr> <td class="tg-1fyk" rowspan="2">Gimbal</td> <td class="tg-1fyk">Field of Regard</td> <td class="tg-1fyk">Pan:<br>Tilt:</td> </tr> <tr> <td class="tg-1fyk">Stabilization</td> <td class="tg-1fyk">&lt;80</td> </tr> <tr> <td class="tg-bxmm" colspan="2">Communication</td> <td class="tg-ds5w">Control<br>Video out<br>UDP, TCP</td> </tr> <tr> <td class="tg-bxmm" colspan="2">Video Output</td> <td class="tg-ds5w">H.264<br>TS/RTP</td> </tr> <tr> <td class="tg-tb6m" colspan="2">Video Tracker</td> <td class="tg-ds5w">Yes</td> </tr> <tr> <td class="tg-1fyk" rowspan="3">Dimensions</td> <td class="tg-ds5w">Diameter</td> <td class="tg-ds5w">140mm</td> </tr> <tr> <td class="tg-ds5w">Height</td> <td class="tg-ds5w">172mm</td> </tr> <tr> <td class="tg-ds5w">Weight</td> <td class="tg-ds5w">g 1350</td> </tr> <tr> <td class="tg-1fyk" rowspan="2">Electronics</td> <td class="tg-ds5w">Operating Voltage</td> <td class="tg-ds5w">12-24V</td> </tr> <tr> <td class="tg-ds5w">Power Consumption</td> <td class="tg-ds5w">11W average / 25W Max</td> </tr> <tr> <td class="tg-tb6m" colspan="2">Temperature</td> <td class="tg-ds5w">-10C to +55C</td> </tr> </tbody> </table>
                          </div>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                  {{ item.content }}
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  export default {
    data () {
      return {
        tab: null,
        items: [
          {
            tab: 'Specifications',
            image: 'side.png',
            list: {
              'Number of Motors for VTOL' : '4',
              'Number of Motors for cruise' : '1',
              'Wingspan' : '6.4m',
              'Fuselage Length' : '2.7m',
              'Propulsion Type' : 'Electric Power',
              'Duration' : '8 hours',
              'Navigation / Stabilization' : 'IMU, GPS',
              'Maximum Takeoff Weight' : '27kg',
              'Control' : 'Fully autonomous'
            }
          },
          {
            tab: 'Payload',
            image: 'payload.png',
            table: true,
          },
          {
            tab: 'Ground Control System',
            image: 'gcs.png',
            list: {
              'Design for maximum mobility.' : '',
              'Equipped with ruggedized touch-screen tablet PC running an advanced powerful mission software.' : '',
              'Design for outdoor, all-weather operation.' : '',
              'Ergonomic design' : ''
            }
          },
          {
            tab: 'Ground Data Terminal',
            image: 'gdt.png',
            list: {
              'Auto tracking.' : '',
              'High gain 19 dBi antenna.' : '',
              'Design for outdoor, all-weather operation.' : '',
              'Ergonomic design' : ''
            }
          },
          {
            tab: 'Mission Software',
            image: 'software.png',
            list: {
              'Provides the operator with all the needed flight data and real time video image allow the operator to define targets, coordinates, etc.' : ''
            },
          },
          {
            tab: 'Transport Case',
            image: 'case.png',
            list: {
              'Easy to cary and good to protect' : ''
            },
          },
        ],
      }
    },

    methods: {
      getImage(name) {
        return require(`@/assets/${name}`)
      }
    }
  }
</script>

<style scoped>
  .tg  {border-collapse:collapse;border-spacing:0;}
  .tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
    overflow:hidden;padding:10px 5px;word-break:normal;}
  .tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
    font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
  .tg .tg-tb6m{background-color:#000000;border-color:#656565;text-align:center;vertical-align:top}
  .tg .tg-1fyk{background-color:#000000;border-color:#656565;text-align:left;vertical-align:middle}
  .tg .tg-bxmm{background-color:#000000;border-color:#656565;text-align:center;vertical-align:middle}
  .tg .tg-ds5w{background-color:#000000;border-color:#656565;text-align:left;vertical-align:top}
  @media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;}}

  .item {
    justify-content: space-between;
    margin-top: 1rem;
  }

  #sokol {
    height: 150vh;
    justify-content: center;
    align-content: center;
  }
</style>