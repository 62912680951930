<template>
  <section id="home">
    <v-container fill-height>
      <v-row justify="center">
        <v-col cols="12" lg="8">
          <div class="heading">
            <h2 class="title">Safe Long-Term Reliable Crypto Profits Automated Crypto Trading</h2>
            <p class="subtitle my-5">Hydra is an automated trading solution (ATS/Bot) focused on the biggest cryptocurrency marketplaces such as Coinbase Pro, Kucoin, Binance and Binance US. The main objectives of Hydra is to provide long-term, safe and reliable gains.</p>
          </div>

          <div class="actions">
            <v-row>
              <v-col cols="12" lg="3">
                <v-btn color="primary" x-large block>Try Now</v-btn>
              </v-col>
            </v-row>
          </div>

          <div class="d-flex flex-row my-10">
            <div>
              <h2 class="info-title">5K+</h2>
              <p class="subtitle my-1">Total Happy Clients</p>
            </div>

            <div class="mx-10">
              <h2 class="info-title">187</h2>
              <p class="subtitle my-1">Supported Countries</p>
            </div>

            <div class="mx-10">
              <h2 class="info-title">$1M+</h2>
              <p class="subtitle my-1">Total Profits</p>
            </div>
          </div>
        </v-col>
        <v-col lg="4">
          <v-img src="../assets/btc.svg" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
}
</script>

<style lang="scss" scoped>
#home {
  height:95vh;
}
.title {
   font-size: 6rem !important;
   font-weight: 600;
   line-height: 6rem;
   letter-spacing: -0.015625em !important;
   font-family: 'Ubuntu' !important;
}

.info-title {
   font-size: 3rem !important;
   font-weight: 600;
   line-height: 3rem;
   letter-spacing: -0.015625em !important;
   font-family: 'Ubuntu' !important;
}

.subtitle {
  color: #7b6e8c;
}
</style>