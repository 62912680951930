<template>
  <section id="home">
    <v-container fluid fill-height>
      <v-row>
        <v-img src="../assets/test.jpg" max-height="1080"/>
      </v-row>
    </v-container>

    <div class="about_us">
      <v-container fluid style="background: black">
        <v-row justify="center" align="center" class="py-5">
          <v-col lg="6">
            <p class="title">Airborne Solutions Ltd. was created to provide first-class services to our clients using high-performance Unmanned Aerial Systems. We provide solutions for aerial observation, tracking, surveillance and reconnaissance to commercial, industrial and governmental institutions.</P>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Header',
  
  computed: {
    mobile: function () {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
    }
  },
  
  mounted() {
    console.log(this.$vuetify.breakpoint.sm)
  },
}
</script>

<style lang="scss">
#home {
  height:95vh;
  position: relative;
}

.about_us {
  width: 100%;
  margin-top: 2px;
  position: absolute;
  bottom: 0;
}
</style>