<template>
  <v-app>

    <v-app-bar fixed>
      <v-toolbar-title class="display-2">
        <v-img src="./assets/logo_blue.png" />
      </v-toolbar-title>

      <v-spacer />

      <v-btn @click="$vuetify.goTo('#home', options)" text>
        Home
      </v-btn>

      <v-btn @click="$vuetify.goTo('#sokol', {duration: 800, offset: -200})" text>
        Sokol 640
      </v-btn>

      <v-btn @click="$vuetify.goTo('#contacts', options)" text>
        Contacts
      </v-btn>
    </v-app-bar>

    <v-main style="padding: 0">
      <Header />
      <Sokol />

      <v-footer id="contacts" color="transparent" elevation="5">
        <v-container>
          <v-row>
            <v-col class="d-flex">
              <v-img src="./assets/test.jpg" max-width="250" contain />
              <div class="px-3">
                Airborne Solutions Ltd.<br />
                56 Probuda Street,<br />
                Bourgas 8000<br />
                Bulgaria<br />
                Tel: +359888240364<br />
                E-mail: info@airbornesolutions.eu<br />
              </div>
            </v-col>
            <v-col>
              <span style="position: absolute; bottom: 0; right:0; font-size: 0.725rem">Copyright &copy; 2022 | Airborne Solutions Ltd.</span>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import Header from '@/components/Header.vue'
import Sokol from '@/components/Sokol.vue'

export default {
  name: 'App',
  components: {
    Header,
    Sokol
  },
  
  data: () => ({
    duration: 700,
    offset: 0,
    easing: 'easeInOutCubic',
  }),

  computed: {
    options () {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      }
    },
  }
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

  .v-application {
    background: rgb(59,49,118) !important;
    background: linear-gradient(135deg, rgba(45, 52, 54,1.0) 0%, rgba(99, 110, 114,1.0) 100%) !important;
    font-family: 'Ubuntu', sans-serif !important;
  }
</style>
